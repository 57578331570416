.header-content{
    display:flex;
    min-height: 75vh;
   
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
}

.header-content h1{
    font-size: 3rem;
    color: #fff;
    text-align: center;
    margin-bottom: 10px;}
 

.holder{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    background: linear-gradient(to right, #4b6cb7, #182848);
    letter-spacing: 1px;
    cursor: pointer;
    transition: transform 0.3s ease;
    background: linear-gradient(rgba(141, 39, 174, 0.3), rgba(141, 39, 174, 0.5)), url("../../assets/poster1.jpg") center/cover no-repeat;
font-size: large;

}