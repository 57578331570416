.movie-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .movie-card {
    margin: 1rem;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-family: Arial, sans-serif;
    font-size: 1rem;
    cursor: pointer;
    color: #fff;
    position: relative;
    overflow: hidden;
    width: calc(20% - 2rem); /* 20% width for 5 cards in a row */
    max-width: 300px; /* Limiting max width for better presentation */
    transition: transform 0.3s ease;
  }
  
  .movie-card:hover {
    transform: translateY(-5px);
  }
  
  .movie-image {
    width: 100%;
    border-radius: 0.5rem;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: opacity 0.3s ease;
    padding: 0.5rem;
    box-sizing: border-box;
  }
  
  .overlay:hover {
    opacity: 1;
  }
  
  .movie-title {
    margin-bottom: 0.5rem;
    font-size: 1.3rem;
    font-weight: bold;
  }
  
  .movie-info {
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }
  
  .span{
    color: #f1c40f;
    font-size: large;
  }



@media (max-width: 768px) {
    .movie-card {
        width: calc(50% - 2rem); /* 50% width for 2 cards in a row */
    }
}

.movie-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
}