.movie-container-custom {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.movie-card-custom {
    margin: 1rem;
    border-radius: 0.5rem;
    overflow: hidden;
    width: calc(20% - 2rem); /* 25% width for 4 cards in a row */
    max-width: 300px; /* Limiting max width for better presentation */
    position: relative;
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); /* Added box shadow */
    cursor: pointer; /* Added cursor pointer */
}

.movie-card-custom:hover .movie-overlay-custom {
    opacity: 1;
}

.movie-image-custom {
    width: 100%;
    height: auto;
}

.movie-overlay-custom {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Changed background color */
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: opacity 0.3s ease;
    padding: 1rem;
    box-sizing: border-box;
}

.movie-overlay-custom p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.5; /* Added line height */
    color: #fff;
}

.movie-card-custom {
    margin: 1rem;
    border-radius: 0.5rem;
    overflow: hidden;
    width: calc(20% - 2rem); /* Slightly reduced width */
    max-width: 300px; /* Reduced max width */
    position: relative;
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    cursor: pointer;
    padding: auto;
}
.movie-details-custom h2 {
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    font-weight: bold;
    padding: 10px;
    font: bold;
}

.movie-details-custom p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.5; /* Added line height */
    padding-left: 10px;
}


@media screen and (max-width: 768px) {
    .movie-card-custom {
        width: calc(50% - 2rem); /* 50% width for 2 cards in a row */
        max-width: 100%; /* Full width for better presentation */
    }
}