/* styles.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Your custom styles here */

body {
  margin: auto;
  padding: 10px;
  box-sizing: border-box;
  background: linear-gradient(to right, #4b6cb7, #182848);
  font-family: "Poppins", sans-serif;
  color: white;
}
